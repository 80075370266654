import { useAuth } from '@agentnet/auth';
import { pxToRem } from '@fluentsms/agentnet-web-components';
import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getFileInfoProps } from 'api/file/file-api';
import { getJackets } from 'api/jacket-api';
import {
  cancelUwrRequest,
  getDecryptedFileId,
  getUnderwritingDocument,
  getUnderwritingRequest,
  reopenUwrRequest,
  saveUnderwritingRequest,
} from 'api/underwriting-api';
import { Jacket } from 'features/jackets/types';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { openDocument } from 'utilities/utilities';
import { RequestInformationSchema } from 'utilities/validation/schemas/underwriting-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { useViewState } from '../../hooks/ViewStateContext';
import { DocumentType, newWindow, WindowFeatures } from '../constants';
import { underwritingText } from './constants';
import {
  CancelUnderwritingRequest,
  CancelUnderwritingResponse,
  GetUnderwritingDocumentResponse,
  GetUnderwritingResponse,
  ReopenUnderwritingRequest,
  ReopenUnderwritingResponse,
  UnderwritingRequest,
  UnderwritingRequestInformation,
  UnderwritingRisk,
  UnderwritingTemplateMetaData,
  UnderwritingValidationError,
} from './types';
import './Underwriting.scss';
import UnderwritingDocumentsFC from './UnderwritingDocuments';
import UnderwritingDocumentsSliderFC from './UnderwritingDocumentsSlider';
import UnderwritingProposedPolicies from './UnderwritingProposedPolicies';
import UnderwritingRequestInformationFC from './UnderwritingRequestInformationFC';
import UnderwritingRisks from './UnderwritingRisks';

const Underwriting = (): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      underwritingWrap: {
        padding: pxToRem(0, 11, 88),
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
    }),
  );
  const classes = useStyles();
  const { fileId } = useParams<getFileInfoProps>();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile, userFirm } = profileCtx;
  const { fileData, loadFileData } = fileDataCtx;
  const encryptedFileId = fileId;

  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();
  const associatedOffice = userFirm?.offices?.find((office) => office.officeId === fileData?.officeId);
  const associatedAccount = associatedOffice?.accounts?.find((account) => account.accountId === fileData?.accountId);
  const isFileActive =
    userFirm?.status?.toLowerCase() === 'active' &&
    associatedOffice?.status?.toLowerCase() === 'active' &&
    associatedAccount?.status?.toLowerCase() === 'active';
  const { getAccessToken } = useAuth();
  const randomId = () => (Math.random() + 1).toString(36).substring(7);
  const { routerParamValue, setRouterParamValue } = useViewState();

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue, fileId]);

  const fetchUnderwritingReq = async (): Promise<GetUnderwritingResponse> => {
    const token = await getAccessToken();
    return getUnderwritingRequest(fileId ?? '', fileData?.accountId ?? 0, fileData?.propertyState ?? '', token);
  };

  const fetchDecryptedFileId = async (): Promise<number> => {
    const token = await getAccessToken();
    return getDecryptedFileId(fileId ?? '', token);
  };

  // Defining State Variables
  const [hasUWR, setHasUwr] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [underwritingRequest, setUnderwritingRequest] = useState<UnderwritingRequest>();
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [selectedViewDocument, setSelectedViewDocument] = useState<any>('');
  const [isSearching, setSearchFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isUwrSubmitConfirmed, setIsUwrSubmitConfirmed] = useState<boolean>(false);
  const [clearDocuments, setClearDocuments] = useState<boolean>(false);
  const [isUwrCancellation, setIsUwrCancellation] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [IsVisible, SetIsVisible] = useState<boolean>(false);
  const [IsCancelHidden, SetIsCancelHidden] = useState<boolean>(false);
  const [isUwrDisabled, setIsUwrDisabled] = useState<boolean>(false);
  const [reopenConformation, setReopenConformation] = useState(false);
  const [reopenReason, setReopenReason] = useState<string>('');
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [estimatedDate, setEstimatedDate] = useState<string | null>('');
  const [isDuplicateRisk, setIsDuplicateRisk] = useState<boolean>(false);
  const [uwrErrorStatus, setUwrErrorStatus] = useState<UnderwritingValidationError>({
    RequestInformation: true,
    Risks: true,
    ProposedPolicies: true,
  });

  // Fectching Jackt Information
  const fetchJacketsReq = async (): Promise<Jacket[]> => {
    const token = await getAccessToken();
    return getJackets(fileId ?? '', token);
  };

  const cleanUWRequest = (underwritingRequest: UnderwritingRequest): UnderwritingRequest => {
    underwritingRequest.TemplateMetaData = {} as UnderwritingTemplateMetaData;

    underwritingRequest.RequestInformation.TransactionType =
      underwritingRequest.RequestInformation.TransactionType == ''
        ? null
        : underwritingRequest.RequestInformation.TransactionType;

    if ((underwritingRequest.ProposedPolicies?.length ?? 0) > 0) {
      underwritingRequest.ProposedPolicies = underwritingRequest.ProposedPolicies.map((policy) => {
        policy.Id = 0;
        policy.EndorsementsOptions = [];
        return policy;
      });
    }

    if ((underwritingRequest.Risks?.length ?? 0) > 0) {
      underwritingRequest.Risks = underwritingRequest.Risks.filter((risk) => {
        return risk.Name != '';
      }).map((risk) => {
        risk.Id = '0';
        return risk;
      });
    }
    return underwritingRequest;
  };

  const postUnderwritingReq = async (): Promise<any> => {
    const token = await getAccessToken();
    let uwrRequest = { ...underwritingRequest } as UnderwritingRequest;
    uwrRequest = cleanUWRequest(uwrRequest);

    // uwrRequest.RequestInformation.HiLiDecisionStatus = isSubmit
    //   ? 'Submit'
    //   : uwrRequest.RequestInformation.HiLiDecisionStatus;
    uwrRequest.RequestInformation.RequestStatus = isSubmit
      ? 'Submitted'
      : uwrRequest.RequestInformation?.RequestStatus ?? 'Prep';
    return saveUnderwritingRequest(fetchDecryptedFileIdValue ?? 0, fileData?.accountId ?? 0, uwrRequest, token);
  };

  const fetchUnderwritingDocument = async (): Promise<GetUnderwritingDocumentResponse> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    const documentId = [];
    documentId.push(selectedViewDocument);
    return getUnderwritingDocument(fetchDecryptedFileIdValue ?? 0, fileData?.accountId ?? 0, documentId, token);
  };

  const cancelUnderwritingRequest = async (): Promise<CancelUnderwritingResponse> => {
    const token = await getAccessToken();
    const cancelReq = {
      RequestId: underwritingRequest?.RequestInformation.RequestId,
      Notes: cancelReason,
    } as CancelUnderwritingRequest;
    return cancelUwrRequest(fetchDecryptedFileIdValue ?? 0, fileData?.accountId ?? 0, cancelReq, token);
  };

  const reopenUnderwritingRequest = async (): Promise<ReopenUnderwritingResponse> => {
    const token = await getAccessToken();
    const reopenReq = {
      RequestId: underwritingRequest?.RequestInformation.RequestId,
      Notes: reopenReason ?? '',
    } as ReopenUnderwritingRequest;
    return reopenUwrRequest(fetchDecryptedFileIdValue ?? 0, fileData?.accountId ?? 0, reopenReq, token);
  };

  const {
    execute: executePostUnderwriting,
    value: postUnderwritingResponseValue,
    status: postUnderwritingResponseStatus,
    errors: postUnderwritingResponseError,
  } = useAsync<any>(postUnderwritingReq, false);

  const {
    execute: executeCancelUnderwriting,
    value: cancelUwrResponseValue,
    status: cancelUwrResponseStatus,
    errors: cancelUwrResponseError,
  } = useAsync<CancelUnderwritingResponse>(cancelUnderwritingRequest, false);

  const {
    execute: executeReopenUnderwriting,
    value: reopenUwrResponseValue,
    status: reopenUwrResponseStatus,
    errors: reopenUwrResponseError,
  } = useAsync<ReopenUnderwritingResponse>(reopenUnderwritingRequest, false);

  const {
    execute: executeGetUwrDocumentData,
    value: getUnderwritingDocumentValue,
    // status: getUnderwritingDocumentStatus,
    //errors: getUnderwritingDocumentError,
  } = useAsync<any>(fetchUnderwritingDocument, false);

  const {
    execute: executeGetJackets,
    value: getJacketsReqValue,
    status: getJacketsReqStatus,
  } = useAsync<Jacket[]>(fetchJacketsReq, false);

  useEffect(() => {
    if (!getJacketsReqValue) executeGetJackets().then();
  }, [fileId]);

  const tableRef = useRef<HTMLDivElement>(null);

  // Called once UWR request is saved/ submitted
  useEffect(() => {
    setSearchFlag(false);
    if (postUnderwritingResponseValue) {
      if (postUnderwritingResponseValue.UnderwritingResponse.RequestId > 0) {
        const uwrRequest = { ...underwritingRequest } as UnderwritingRequest;
        uwrRequest.RequestInformation.HiLiDecisionStatus =
          uwrRequest.RequestInformation.RequestStatus === 'Submitted' &&
          (postUnderwritingResponseValue.UnderwritingResponse.Status === 'In Progress' || isSubmit)
            ? 'Submit'
            : uwrRequest.RequestInformation.HiLiDecisionStatus;
        uwrRequest.RequestInformation.RequestId = postUnderwritingResponseValue.UnderwritingResponse.RequestId;
        uwrRequest.RequestInformation.RequestStatus = postUnderwritingResponseValue.UnderwritingResponse.Status;
        uwrRequest.RequestInformation.SubmittedDate = postUnderwritingResponseValue.UnderwritingResponse.SubmittedDate;
        uwrRequest.Documents = postUnderwritingResponseValue.UnderwritingResponse.Documents;
        uwrRequest.ProposedPolicies = postUnderwritingResponseValue.UnderwritingResponse.ProposedPolicies.map(
          (policy: any) => {
            const totalEndorsementOptions = uwrRequest?.TemplateMetaData.EndorsementTypes ?? [];
            // Filter and construct endorsementOptions for popper of the policy
            const filteredEndoOptions = totalEndorsementOptions.filter(
              (e) => !policy.Endorsements.find((s: any) => s.EndorsementTypeId == parseInt(e.Key)),
            );
            policy.EndorsementsOptions = filteredEndoOptions;
            return policy;
          },
        );
        setUnderwritingRequest(uwrRequest);
        setClearDocuments(true);
        SetIsCancelHidden(uwrRequest?.RequestInformation.RequestStatus === 'Prep' ? false : true);

        if (tableRef.current) {
          tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        addSnackbarMessage({
          message: `${isSubmit ? 'Submit' : 'Save'} Underwriting Request Successful`,
          type: 'success',
        });
      } else {
        addSnackbarMessage({
          message: `${isSubmit ? 'Submit' : 'Save'} Underwriting Request Failed`,
          type: 'error',
        });
      }
      setIsUwrSubmitConfirmed(false);
    }
  }, [postUnderwritingResponseValue]);

  // Called once UWR request is cancelled
  useEffect(() => {
    if (['pending', 'idle'].indexOf(cancelUwrResponseStatus) < 0 && cancelUwrResponseValue) {
      if (cancelUwrResponseValue.RequestId > 0) {
        const uwrRequest = { ...underwritingRequest } as UnderwritingRequest;
        uwrRequest.RequestInformation.RequestId = cancelUwrResponseValue.RequestId;
        uwrRequest.RequestInformation.RequestStatus = cancelUwrResponseValue.Status;
        setUnderwritingRequest(uwrRequest);
        SetIsVisible(true);
        addSnackbarMessage({
          message: `Underwriting  Cancel Request Successful`,
          type: 'success',
        });
      } else {
        addSnackbarMessage({
          message: `Underwriting Cancel Request Failed`,
          type: 'error',
        });
      }
      setIsUwrCancellation(false);
    }
  }, [cancelUwrResponseValue, cancelUwrResponseStatus]);

  // Called once UWR request is Reopened
  useEffect(() => {
    if (['pending', 'idle'].indexOf(reopenUwrResponseStatus) < 0 && reopenUwrResponseValue) {
      if (reopenUwrResponseValue.RequestId > 0) {
        const uwrRequest = { ...underwritingRequest } as UnderwritingRequest;
        uwrRequest.RequestInformation.RequestId = reopenUwrResponseValue.RequestId;
        uwrRequest.RequestInformation.RequestStatus = 'In Progress';
        uwrRequest.RequestInformation.HiLiDecisionStatus = 'ReOpen';
        setUnderwritingRequest(uwrRequest);
        // setIsUwrDisabled(true);
        SetIsVisible(false);
        SetIsCancelHidden(uwrRequest?.RequestInformation.RequestStatus === 'Prep' ? false : true);
        addSnackbarMessage({
          message: `Underwriting  Reopen Request Successful`,
          type: 'success',
        });
      } else {
        addSnackbarMessage({
          message: `Underwriting Reopen Request Failed`,
          type: 'error',
        });
      }
      setReopenConformation(false);
    }
  }, [reopenUwrResponseValue, reopenUwrResponseStatus]);

  useEffect(() => {
    if (selectedViewDocument !== '') {
      setSelectedViewDocument('');
      executeGetUwrDocumentData().then();
    }
  }, [selectedViewDocument]);

  useEffect(() => {
    if (getUnderwritingDocumentValue?.UnderwritingDocuments.length > 0) {
      const orderDocumentBytes = getUnderwritingDocumentValue?.UnderwritingDocuments[0].Binary;
      let docType = '';

      switch (getUnderwritingDocumentValue?.UnderwritingDocuments[0].FileType.toUpperCase()) {
        case 'XML':
          docType = 'application/xml';
          break;
        case 'PDF':
          docType = DocumentType;
          break;
        case 'JPG':
        case 'JPEG':
        case 'PNG':
          docType = 'image/jpeg';
          break;
        case 'GIF':
          docType = 'image/gif';
          break;
        case 'BMP':
          docType = 'image/bmp';
          break;
        case 'TIFF':
        case 'TIF':
          docType = 'image/tiff';
          break;
        case 'DOC':
          docType = 'application/msword';
          break;
        case 'RTF':
          docType = 'application/msword';
          break;
        case 'TXT':
          docType = 'text/plain';
          break;
        case 'XLS':
          docType = 'application/vnd.ms-excel';
          break;
        case 'XPS':
          docType = 'application/vnd.ms-xpsdocument';
          break;
        case 'WPS':
          docType = 'application/vnd.ms-works';
          break;
        case 'DOCX':
          docType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'XLSX':
          docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'ODT':
          docType = 'application/vnd.oasis.opendocument.formula';
          break;
        case 'WPD':
          docType = 'application/wordperfect';
          break;
        case 'ESP':
          docType = 'application/octet-stream';
          break;
        case 'HTM':
          docType = 'text/html';
          break;
        case 'MSG':
          docType = 'application/vnd.ms-outlook';
          break;
        case 'JSON':
          docType = 'application/json';
          break;
        default:
          docType = DocumentType;
          break;
      }
      openDocument(orderDocumentBytes, docType, newWindow, WindowFeatures);
      setSearchFlag(false);
    }
  }, [getUnderwritingDocumentValue]);

  // Fetching Underwritting FileId in decrypted format
  const {
    execute: executefetchDecryptedFileId,
    value: fetchDecryptedFileIdValue,
    // status: fetchDecryptedFileIdStatus,
  } = useAsync<number>(fetchDecryptedFileId, false);

  // Fetching Underwritting Request Data
  const {
    execute: executeGetUnderwritingReq,
    value: getUnderwritingReqValue,
    status: getUnderwritingReqStatus,
    errors: getUnderwritingRespError,
  } = useAsync<GetUnderwritingResponse>(fetchUnderwritingReq, false);

  useEffect(() => {
    setDisplayError(
      postUnderwritingResponseStatus === 'error' ||
        cancelUwrResponseStatus === 'error' ||
        reopenUwrResponseStatus === 'error',
    );
  }, [postUnderwritingResponseStatus, cancelUwrResponseStatus, reopenUwrResponseStatus]);

  useEffect(() => {
    setErrMsgs([]);
    if (displayError) {
      const errorMessages = postUnderwritingResponseError?.length
        ? postUnderwritingResponseError
        : cancelUwrResponseError?.length
        ? cancelUwrResponseError
        : reopenUwrResponseError?.length
        ? reopenUwrResponseError
        : [];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  const initializeUnderwritingRequestData = () => {
    if (!getUnderwritingReqValue) {
      return;
    }

    if ((getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation?.RequestId ?? 0) > 0) {
      getUnderwritingReqValue.UnderwritingRequest.Risks = getUnderwritingReqValue.UnderwritingRequest.Risks.map(
        (risk) => {
          risk.Definition = getUnderwritingReqValue.UnderwritingRequest.TemplateMetaData.Risks.filter((riskMeta) => {
            return riskMeta.Name === risk.Name;
          })[0].Definition;
          risk.IsExpanded = true;
          return risk;
        },
      );
      getUnderwritingReqValue.UnderwritingRequest.ProposedPolicies =
        getUnderwritingReqValue.UnderwritingRequest.ProposedPolicies.map((policy) => {
          policy.IsExpanded = true;
          return policy;
        });
    }

    if ((getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation?.RequestId ?? 0) === 0) {
      // Calculate MaximumLiabilityAmount Default value
      let calculatedLiabilityAmt = 0;

      // Get Max PolicyAmt if active jackets available
      if (getJacketsReqValue?.length != 0 && getJacketsReqValue?.some((jacket) => jacket.policyStatus === 'Open')) {
        const activeJackets = getJacketsReqValue?.filter((jacket) => jacket.policyStatus === 'Open');
        const policyAmtArr = activeJackets?.map((eachJacket) => eachJacket.policyAmount) as Array<number>;
        calculatedLiabilityAmt = Math.max(...policyAmtArr);
      } else {
        calculatedLiabilityAmt =
          (fileData?.loanAmount ?? 0) > (fileData?.salesPrice ?? 0)
            ? fileData?.loanAmount ?? 0
            : fileData?.salesPrice ?? 0;
      }

      getUnderwritingReqValue.UnderwritingRequest.RequestInformation = {
        RequestId: 0,
        RequestName: getUnderwritingReqValue.FileNumber,
        RequestStatus: 'Prep',
        AssignedTo: void 0,
        TransactionType: '',
        EmailNotification: profile?.emailAddress,
        MaximumLiabilityAmount: calculatedLiabilityAmt,
        EstimatedClosingDate: fileData?.closingDate ?? undefined,
        IsMortgageModified: false,
        IsMultisite: false,
      } as UnderwritingRequestInformation;

      getUnderwritingReqValue.UnderwritingRequest.Risks = [
        {
          Name: getUnderwritingReqValue.UnderwritingRequest.TemplateMetaData.Risks[0].Name,
          Definition: getUnderwritingReqValue.UnderwritingRequest.TemplateMetaData.Risks[0].Definition,
          IsExpanded: true,
          Id: randomId(),
        } as UnderwritingRisk,
      ] as Array<UnderwritingRisk>;
    }

    if (!getUnderwritingReqValue.UnderwritingRequest.RequestInformation.AssignedTo) {
      getUnderwritingReqValue.UnderwritingRequest.RequestInformation.AssignedTo =
        getUnderwritingReqValue.UnderwritingRequest.TemplateMetaData.Approvers[0].Name.toString();
    }
  };
  useEffect(() => {
    SetIsVisible(
      getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation.RequestId === 0 ||
        getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation.RequestStatus === '' ||
        getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation.RequestStatus === 'Prep' ||
        getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation.RequestStatus === 'In Progress' ||
        getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation.RequestStatus === 'Submitted'
        ? false
        : true,
    );
    SetIsCancelHidden(
      getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation.RequestStatus === 'Prep' ? false : true,
    );
  }, [getUnderwritingReqValue]);

  useEffect(() => {
    if (!getUnderwritingReqValue && fileData?.accountId && fileData?.accountId > 0) executeGetUnderwritingReq().then();
    if (!fetchDecryptedFileIdValue) executefetchDecryptedFileId().then();
  }, [fileId, fileData?.accountId]);

  useEffect(() => {
    if (fileData?.fileStatus) {
      setIsUwrDisabled(
        fileData?.fileStatus === 'Opened in Error' ||
          fileData?.fileStatus === 'Cancelled' ||
          fileData?.fileStatus === 'Closed'
          ? true
          : false,
      );
    }
  }, [fileData?.fileStatus]);

  useEffect(() => {
    if (!fileData?.fileId) loadFileData && loadFileData({ fileId: encryptedFileId });
  }, [fileData?.fileId]);

  // On page load get underwriting and jacket data
  useEffect(() => {
    if (['pending', 'idle'].indexOf(getJacketsReqStatus) < 0 && getUnderwritingReqValue) {
      initializeUnderwritingRequestData();
      setHasUwr(getUnderwritingReqValue?.UnderwritingRequest?.RequestInformation?.RequestId != 0);
      setIsCreateMode(true);
      setUnderwritingRequest(getUnderwritingReqValue.UnderwritingRequest);
      const issuedDateFormat = getUnderwritingReqValue.UnderwritingRequest.RequestInformation.EstimatedClosingDate
        ? moment(getUnderwritingReqValue.UnderwritingRequest.RequestInformation.EstimatedClosingDate).format(
            'MM/DD/YYYY',
          )
        : null;
      setEstimatedDate(issuedDateFormat);
    } else if (getUnderwritingReqStatus === 'error' && getUnderwritingRespError.length > 0) {
      getUnderwritingRespError?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [getUnderwritingReqValue, getJacketsReqStatus]);

  const hasNoError = () => {
    return Object.values(uwrErrorStatus).every((item) => item === true);
  };

  const validateRequestInformationErrors = async (): Promise<boolean> => {
    const errs: FieldValidationError[] =
      (await doValidate(underwritingRequest?.RequestInformation, RequestInformationSchema)) || [];
    return errs.length === 0;
  };

  // Handle Save/ Submit UWR
  const saveRequest = async (isSubmit: boolean) => {
    setIsSubmit(isSubmit);
    setSubmitAttempted(true);
    const hasRequestInfoErrors = await validateRequestInformationErrors();
    if (hasNoError() && !isDuplicateRisk && hasRequestInfoErrors) {
      if (isSubmit) {
        // show popup for submit and handle api call
        setIsUwrSubmitConfirmed(true);
      } else {
        // call API on save click
        setSearchFlag(true);
        setClearDocuments(false);
        executePostUnderwriting().then();
      }
    } else {
      addSnackbarMessage({
        message: `${isSubmit ? 'Submit' : 'Save'} Underwriting Request Failed`,
        type: 'error',
      });
      setIsSubmit(false);
    }
  };
  const isCoveredpartiesSelected = (val: boolean) => {
    setIsUwrSubmitConfirmed(val);
  };

  const confirmUwrSubmit = () => {
    setSearchFlag(true);
    // Call API
    setClearDocuments(false);
    executePostUnderwriting().then();
  };

  const cancelUwrSubmit = () => {
    setIsUwrSubmitConfirmed(false);
    setIsSubmit(false);
    setSubmitAttempted(false);
    setSearchFlag(false);
  };

  //  Handle UWR cancel request after UWR Submit
  const cancelUnderwriting = () => {
    setIsUwrCancellation(true);
  };

  const dismissUwrCancelRequest = () => {
    if (cancelUwrResponseStatus === 'pending') return;
    setIsUwrCancellation(false);
    setCancelReason('');
  };

  const initiateUWRCancellation = () => {
    // execute uwr cancellation
    executeCancelUnderwriting().then();
  };

  // Handle UWR reopen request after UWR approved/ declined/ Cancelled
  const dismissUwrReopenRequest = () => {
    if (reopenUwrResponseStatus === 'pending') return;
    setReopenConformation(false);
    setReopenReason('');
  };

  const initiateUwrReopening = () => {
    // Execute Reopen Underwriting
    executeReopenUnderwriting().then();
  };

  const handleUpdateUnderwritingRequest = (propertyName: string, value: any) => {
    const updatedValue: any = { ...underwritingRequest, [propertyName]: value };
    setUnderwritingRequest(updatedValue);
  };

  const handleUpdateUwrErrorStatus = (propertyName: string, value: boolean) => {
    const updatedValue = { ...uwrErrorStatus, [propertyName]: value };
    setUwrErrorStatus(updatedValue);
  };

  let actionStatus = 'pending';
  if (getUnderwritingReqStatus !== 'pending' && getJacketsReqStatus !== 'pending') {
    actionStatus = 'idle';
  }

  let saveSubmitStatus = 'pending';

  if (postUnderwritingResponseStatus !== 'pending') {
    saveSubmitStatus = 'idle';
  }

  function handleViewDocument(document: any) {
    setSelectedViewDocument(document);
  }

  return (
    <>
      <div className={classes.root} ref={tableRef}>
        <div className="paper-layout contained">
          <LoadingSpinner status={isSearching ? 'pending' : 'success'} variant="linear" />
        </div>
        <div className="paper-layout contained">
          <LoadingSpinner status={saveSubmitStatus} variant="linear" />
        </div>
        <div className="paper-layout contained">
          <div className={actionStatus === 'pending' ? 'loading-container' : ''}>
            <LoadingSpinner status={actionStatus} size={24} />
          </div>
          <LoadingSpinner status={actionStatus} variant="linear" />
          {(hasUWR || isCreateMode) && (
            <div className={classes.underwritingWrap}>
              <Grid container>
                <UnderwritingRequestInformationFC
                  uwr={underwritingRequest}
                  updateUnderwriting={handleUpdateUnderwritingRequest}
                  submitAttempted={submitAttempted}
                  estimatedDate={estimatedDate}
                  setEstimatedDate={(dateVal: string | null) => setEstimatedDate(dateVal)}
                  setSubmitAttempted={setSubmitAttempted}
                  isSubmit={isSubmit}
                  updateUwrErrorStatus={handleUpdateUwrErrorStatus}
                  openDocumentDrawer={() => setOpenDrawer(true)}
                  openReopenConformation={() => setReopenConformation(true)}
                  isUwrDisabled={isUwrDisabled}
                  isDisabled={IsVisible}
                ></UnderwritingRequestInformationFC>

                <UnderwritingRisks
                  uwr={underwritingRequest}
                  updateUnderwriting={handleUpdateUnderwritingRequest}
                  isUwrDisabled={isUwrDisabled}
                  isDisabled={IsVisible}
                  updateUwrErrorStatus={handleUpdateUwrErrorStatus}
                  submitAttempted={submitAttempted}
                  setSubmitAttempted={setSubmitAttempted}
                  isDuplicateRisk={setIsDuplicateRisk}
                  isCoveredpartiesSelected={isCoveredpartiesSelected}
                ></UnderwritingRisks>

                <UnderwritingProposedPolicies
                  uwr={underwritingRequest}
                  updateUnderwriting={handleUpdateUnderwritingRequest}
                  submitAttempted={submitAttempted}
                  setSubmitAttempted={setSubmitAttempted}
                  isSubmit={isSubmit}
                  updateUwrErrorStatus={handleUpdateUwrErrorStatus}
                  isUwrDisabled={isUwrDisabled}
                  isDisabled={IsVisible}
                ></UnderwritingProposedPolicies>

                <UnderwritingDocumentsFC
                  uwr={underwritingRequest}
                  updateUnderwriting={handleUpdateUnderwritingRequest}
                  onViewDocument={handleViewDocument}
                  clearDocuments={clearDocuments}
                  isUwrDisabled={isUwrDisabled}
                  isDisabled={IsVisible}
                ></UnderwritingDocumentsFC>

                <UnderwritingDocumentsSliderFC
                  uwr={underwritingRequest}
                  fileId={fileId}
                  accountNumber={fileData?.accountId}
                  isDrawerOpen={openDrawer}
                  closeDrawer={() => setOpenDrawer(false)}
                  updateUnderwriting={handleUpdateUnderwritingRequest}
                />

                {!isUwrDisabled &&
                  !IsVisible &&
                  (underwritingRequest?.RequestInformation.HiLiDecisionStatus === 'ReOpen' ||
                  underwritingRequest?.RequestInformation.RequestStatus === 'Prep' ? (
                    <BottomAppBar
                      onCancel={() => {
                        cancelUnderwriting && cancelUnderwriting();
                      }}
                      primaryActionLabel={'Submit Request'}
                      onPrimaryClick={() => saveRequest && saveRequest(true)}
                      disableCancel={postUnderwritingResponseStatus === 'pending'}
                      cancelDataQa={'UnderwritingCancel'}
                      primaryActionDataQa={'UnderwritingCreate'}
                      showPrimary={true}
                      secondaryActionLabel={'Save'}
                      secondaryActionDataQa={'UnderwritingSave'}
                      disableSecondary={postUnderwritingResponseStatus === 'pending'}
                      onSecondaryClick={() => saveRequest && saveRequest(false)}
                      disablePrimary={postUnderwritingResponseStatus === 'pending'}
                      accordionView
                      hideCancel={IsCancelHidden}
                      cancelLabel="Cancel Request"
                    />
                  ) : (
                    <BottomAppBar
                      onCancel={() => {
                        cancelUnderwriting && cancelUnderwriting();
                      }}
                      disableCancel={postUnderwritingResponseStatus === 'pending'}
                      cancelDataQa={'UnderwritingCancel'}
                      primaryActionDataQa={'UnderwritingCreate'}
                      showPrimary={true}
                      secondaryActionLabel={'Save'}
                      secondaryActionDataQa={'UnderwritingSave'}
                      disableSecondary={postUnderwritingResponseStatus === 'pending'}
                      onSecondaryClick={() => saveRequest && saveRequest(false)}
                      disablePrimary={false}
                      accordionView
                      hideCancel={IsCancelHidden}
                      cancelLabel="Cancel Request"
                    />
                  ))}
              </Grid>
            </div>
          )}
        </div>
      </div>
      <>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationUwrSubmit"
          onConfirm={confirmUwrSubmit}
          open={isUwrSubmitConfirmed}
          disabled={postUnderwritingResponseStatus === 'pending'}
          disableCrossIconClick={postUnderwritingResponseStatus === 'pending'}
          onDismissAction={cancelUwrSubmit}
          dialogTitle="Submit Underwriting Request"
          dialogBtnContent="Yes, Submit"
          dialogText={underwritingText.SUBMIT_UWR_CONFIRM_MSG}
        />
      </>
      <>
        <AgentNetConfirmationDialog
          onConfirm={initiateUWRCancellation}
          onDismissAction={dismissUwrCancelRequest}
          open={isUwrCancellation}
          size="md"
          disablePrimaryBtn={cancelReason.trim().length > 0 ? false : true}
          dialogTitle={'Cancel Request'}
          dialogBtnContent="Cancel Request"
          qaAttrPrefix="ConfirmationUwrCancel"
          dialogCancelBtnContent="Close"
          disabled={cancelUwrResponseStatus === 'pending'}
          disableCrossIconClick={cancelUwrResponseStatus === 'pending'}
          dialogTextHTML={
            <>
              <Typography variant="body2" className={classes.mb2}>
                {underwritingText.UWR_CANCEL_REQUEST_MSG}
              </Typography>
              <AgentNetTextInput
                variant="outlined"
                name="Notes"
                required
                label="Reason"
                multiline
                fullWidth
                value={cancelReason}
                data-testid={'UnderwritingCancelNotes'}
                onChange={(e: any) => {
                  setCancelReason(e.target.value);
                }}
                className={classes.mb2}
                rows={4}
              />
            </>
          }
        />
      </>
      <>
        <AgentNetConfirmationDialog
          onConfirm={initiateUwrReopening}
          onDismissAction={dismissUwrReopenRequest}
          open={reopenConformation}
          size="md"
          disabled={reopenUwrResponseStatus === 'pending'}
          dialogTitle={'Reopen Underwriting Request'}
          dialogBtnContent="Reopen Request"
          qaAttrPrefix="ConfirmationUwrReopen"
          dialogCancelBtnContent="Cancel"
          dialogTextHTML={
            <>
              <Typography variant="body2" className={classes.mb2}>
                {underwritingText.UWR_REOPEN_REQUEST_MSG}
              </Typography>
              <AgentNetTextInput
                variant="outlined"
                name="Notes"
                label="Note"
                multiline
                fullWidth
                value={reopenReason}
                data-testid={'UnderwritingReopenNotes'}
                onChange={(e: any) => {
                  setReopenReason(e.target.value);
                }}
                className={classes.mb2}
                rows={4}
              />
            </>
          }
        />
      </>
    </>
  );
};

export default Underwriting;
