import { FormatDateUtils } from '@agentnet/utils';
import { DataTable, pxToRem } from '@fluentsms/agentnet-web-components';
import { makeStyles } from '@material-ui/core/styles';
import { ColDef, GridApi } from 'ag-grid-enterprise';
import React, { useState } from 'react';

type BackTitleListProps = {
  rowData: any[];
  fetchDocument: (policyType: string, documentDescription: string, docGuid: string) => void;
};

const BackTitleList: React.FC<BackTitleListProps> = ({ rowData, fetchDocument }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      marginTop: pxToRem(114),
      width: '100%',
    },
    height: {
      height: pxToRem(400),
    },
    pt2: {
      paddingTop: theme.spacing(2),
      marginTop: 0,
    },
    marginFileView: {
      marginTop: 0,
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    noResults: {
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
    },
    backTitleGridContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }));

  const classes = useStyles();
  const allColumnIds = useState([]);

  const getStartOfDay = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const columnDefs: ColDef[] = [
    {
      field: 'DocumentDescription',
      headerName: 'Document',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      cellRenderer: (params: any) => {
        const handleClick = () => {
          fetchDocument(params.data.PolicyType, params.data.DocumentDescription, params.data.DocGuid);
        };

        return (
          <div className="documentName" onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'DocumentType',
      headerName: 'Doc. Type',
      filter: 'agSetColumnFilter',
      minWidth: 120,
    },
    {
      headerName: 'Legal Desc.',
      sortable: false,
      filter: 'agSetColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      cellRenderer: (params: any) => {
        const handleClick = () => {
          fetchDocument('Legal Desc', params.data.DocumentDescription, params.data.DocGuid);
        };

        return (
          params.data.PolicyType === 'TRD' &&
          params.data.HasLegalDescription && (
            <div className="documentName" onClick={handleClick}>
              View
            </div>
          )
        );
      },
    },
    {
      field: 'PropertyAddress',
      headerName: 'Property Address',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 200,
    },
    {
      field: 'Owners',
      headerName: 'Last Name/Entity',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 120,
      maxWidth: 400,
    },
    {
      field: 'CondoSub',
      headerName: 'Condo/Subdivision',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 120,
    },
    {
      field: 'UnitLot',
      headerName: 'Unit/Lot',
      filter: 'agTextColumnFilter',
      suppressFloatingFilterButton: true,
      suppressHeaderFilterButton: true,
      minWidth: 120,
    },
    {
      field: 'PolicyEffectiveDate',
      headerName: 'Order Date',
      minWidth: 190,
      filter: 'agDateColumnFilter',
      filterParams: {
        type: 'date',
        qaAttribute: 'BackTitleOrderDate',
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          const cellDate = getStartOfDay(cellValue);
          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
          }
          if (cellDate.getTime() < filterLocalDateAtMidnight.getTime()) {
            return -1;
          }
          if (cellDate.getTime() > filterLocalDateAtMidnight.getTime()) {
            return 1;
          }
          return 0;
        },
        browserDatePicker: true,
        dateFormat: 'yyyy-mm-dd',
        inRangeInclusive: true,
      },
      valueFormatter: (params) =>
        params.value && params.value !== '0001-01-01T00:00:00' ? FormatDateUtils.MonthDateYear(params.value) : '',
      sort: 'desc',
    },
  ];
  const autoSizeGrid = (params: any) => {
    params.api.getColumns().forEach(function (column: { colId: any }) {
      allColumnIds.push(column.colId);
    });
    params.api.autoSizeColumns(allColumnIds);
  };

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    const defaultSortModel = [{ colId: 'PolicyEffectiveDate', sort: 'desc', sortIndex: 1 }];
    params.api.applyColumnState({ state: defaultSortModel });
  };

  const gridApi = React.useRef<GridApi | null>(null);
  const gridRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="full-width">
      <div className="backtitle-search-result">
        {rowData?.length > 0 && (
          <div className={classes.backTitleGridContainer} style={{ width: '100%', height: '100%' }}>
            <div className="ag-theme-alpine table-grid back-title-grid" ref={gridRef} style={{ position: 'relative' }}>
              <DataTable
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={{
                  cellClass: 'left-align-column',
                  headerClass: 'left-align-header',
                  sortable: true,
                  filter: true,
                  floatingFilter: true,
                  filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                  suppressHeaderMenuButton: true,
                  resizable: true,
                  flex: 1,
                }}
                onFirstDataRendered={autoSizeGrid}
                onGridReady={onGridReady}
                domLayout="autoHeight"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const areEqual = (prevProps: BackTitleListProps, nextProps: BackTitleListProps) => {
  return prevProps.rowData === nextProps.rowData;
};

export default React.memo(BackTitleList, areEqual);
