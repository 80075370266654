import DateFnsUtils from '@date-io/date-fns';
import { IconButtonProps, PopoverProps, TextFieldProps } from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format as formatDate } from 'date-fns';
import ErrorSection from 'features/files/file-create/ErrorSection';
import { useEffect, useState } from 'react';
import { FieldValidationError } from 'utilities/validation/validation';
import './DateField.scss';

export type DateFieldProps = Omit<TextFieldProps, 'onChange'> & {
  format?: string;
  value?: string | null | undefined;
  onChange?: (date: string | null) => void;
  KeyboardDatePickerProps?: Partial<KeyboardDatePickerProps>;
  PopoverProps?: Partial<PopoverProps>;
  KeyboardButtonProps?: IconButtonProps;
  forceUpdate?: boolean;
  label?: string;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  name?: string;
  error?: boolean;
  errs?: FieldValidationError[];
  showValidation?: boolean;
  required?: boolean;
  qaAttribute?: string;
  helperText?: string;
  placeholder?: string;
};

const DateField = ({
  format = 'MM/dd/yyyy',
  helperText = 'Invalid Date',
  value = '',
  onChange = () => undefined,
  onBlur = () => undefined,
  onKeyDown = () => undefined,
  KeyboardDatePickerProps = {},
  PopoverProps = {},
  KeyboardButtonProps = {},
  InputProps,
  inputProps,
  forceUpdate = false,
  error,
  errs,
  showValidation,
  label,
  minDate,
  maxDate,
  disableFuture = false,
  disablePast = false,
  disabled = false,
  name,
  required,
  qaAttribute = '',
  placeholder,
}: DateFieldProps): JSX.Element => {
  const isValidDate = value && Date.parse(value);
  const [inputValue, setInputValue] = useState(isValidDate ? new Date(value) : null);
  const [fieldValidationErrors, setFieldValidationError] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);

  useEffect(() => {
    if (value) {
      const newDate = new Date(value);
      setInputValue(newDate);
    }
    if (value === '' || value === null) {
      setInputValue(null);
      if (inputValue !== null) onChange(null);
    }
  }, [value]);

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setFieldValidationError(true) : setFieldValidationError(false);
  }, [errs]);

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  const handleChange = (date: any) => {
    //in order to accept inbound validation, onChange must be fired on blur
  };

  const handleBlur = (event: any) => {
    const date = event.target.value;
    if (date) {
      setInputValue(date);
      handleChange(date);
      onChange(date);
    } else {
      setInputValue(null);
      onChange(null);
    }
  };

  const handleAccept = (date: any) => {
    onChange(formatDate(date as Date, format));
  };
  const val = value as string;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <>
        <KeyboardDatePicker
          autoOk
          allowKeyboardControl
          inputProps={{
            ...inputProps,
            ...(qaAttribute ? { 'data-testid': qaAttribute } : {}),
            title: val ? val : label ? label.toString() : '',
          }}
          className="date-field-string"
          value={inputValue}
          variant="inline"
          format={format}
          minDate={new Date(minDate ? minDate : '1900-12-21T03:24:00')}
          maxDate={new Date(maxDate ? maxDate : '2100-12-21T03:24:00')}
          onChange={(e) => handleChange(e)}
          onAccept={handleAccept}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          keyboardIcon={<CalendarTodayOutlined fontSize="large" color="primary" />}
          initialFocusedDate={new Date()}
          inputVariant="outlined"
          label={label}
          InputProps={{ fullWidth: true }}
          InputLabelProps={{ shrink: true }}
          KeyboardButtonProps={{ disableFocusRipple: true, disableRipple: true, disableTouchRipple: true }}
          invalidLabel={error ? 'Invalid Date' : ''}
          maxDateMessage={'Invalid Date'}
          minDateMessage={'Invalid Date'}
          {...KeyboardDatePickerProps}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          name={name}
          required={required}
          error={error || (fieldValidationErrors && displayErrors)}
          helperText={error ? helperText : ''}
          placeholder={placeholder ? placeholder : 'mm/dd/yyyy'}
        />
      </>
      {fieldValidationErrors && name && displayErrors && <ErrorSection errs={errs} field={name} />}
    </MuiPickersUtilsProvider>
  );
};

export default DateField;
