import { grey } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    brand: {
      legacy: string;
      blueDark: string;
      brightBlue: string;
      cyan: string;
      cyanDark: string;
      gold: string;
      charcoal: string;
      lightBlue: string;
      lightGrey: string;
      lightGrey50: string;
      lightGrey25: string;
      sky25: string;
      sky50: string;
      lilRed: string;
    };
    actionSecondary: {
      active: string;
      hover: string;
      hoverOpacity: number;
      selected: string;
      disabled: string;
      disabledBackground: string;
      focus: string;
    };
    states: {
      hover: string;
      selected: string;
      focus: string;
      focusVisible: string;
      outlinedBorder: string;
      hoverBorder: string;
      disabledBorder: string;
      disabledBackground: string;
    };
    statesPrimary: {
      hover: string;
      selected: string;
      focus: string;
      focusVisible: string;
      outlinedBorder: string;
      hoverBorder: string;
      disabledBorder: string;
      disabledBackground: string;
    };
    agGrid: {
      disabledBackground: string;
      overrideBackground: string;
      overrideBackgroundDisabled: string;
      overrideBorder: string;
      overrideBorderHover: string;
    };
  }
  interface PaletteOptions {
    brand?: Palette['brand'];
    actionSecondary?: Palette['actionSecondary'];
    states?: Palette['states'];
    statesPrimary?: Palette['statesPrimary'];
    agGrid?: Palette['agGrid'];
  }
}

const palette = createPalette({
  text: {
    primary: grey[900],
    secondary: grey[700],
    disabled: grey[500],
  },
  primary: {
    main: '#0075C3',
    dark: '#00497A',
    light: '#D0E3F5',
    contrastText: '#FFFFFF',
  },
  statesPrimary: {
    hover: '#0075C30a',
    selected: '#0075C314',
    focus: '#0075C31f',
    focusVisible: '#0075C34d',
    outlinedBorder: '#0075C380',
    hoverBorder: '#0075C3b3',
    disabledBorder: '#0075C34d',
    disabledBackground: '#0075C30f',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  secondary: {
    main: '#65788F',
    dark: '#3D4D5F',
    light: '#D5DCE2',
    contrastText: '#FFFFFF',
  },
  states: {
    hover: '#65788f0a',
    selected: '#65788f14',
    focus: '#65788f1f',
    focusVisible: '#65788f4d',
    outlinedBorder: '#65788f80',
    hoverBorder: '#65788fb3',
    disabledBorder: '#65788f4d',
    disabledBackground: '#65788f0f',
  },

  error: {
    main: '#AC1209', // red/600
    dark: '#9A0C05', // red/800
    light: '#BE382F', // red/400
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FB8C00', // orange/600
    dark: '#EF6C00', // orange/800
    light: '#FFA726', // orange/400
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#039BE5', // lightBlue/600
    dark: '#0277BD', // lightBlue/800
    light: '#29B6F6 ', // lightBlue/400
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#43A047', // green/600
    dark: '#2E7D32', // green/800
    light: '#66BB6A', // green/400
    contrastText: '#FFFFFF',
  },

  divider: '#CFD8DC', // blueGray/100
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  action: {
    active: '#0075C3', // primary/main
    hover: '#0075C30a',
    hoverOpacity: 0.12,
    selected: '#0075C314',
    disabled: '#0075C361',
    disabledBackground: '#0075C31F',
    focus: '#0075C31f',
  },
  actionSecondary: {
    active: '#65788F', // secondary/main
    hover: '#65788f0a',
    hoverOpacity: 0.04,
    selected: '#65788f14',
    disabled: '#65788f61',
    disabledBackground: '#65788f1f',
    focus: '#65788f1f',
  },
  brand: {
    legacy: '#013A6F',
    blueDark: '#141D3A',
    cyan: '#4EADE1',
    cyanDark: '#1688C5',
    gold: '#EBBD5F',
    charcoal: '#2F2F2F',
    lightGrey: '#F4F4F4', //feather
    lightGrey50: '#F9F9F9', //feather50
    lightGrey25: '#FAFAFA', //feather25
    brightBlue: '#0075C3',
    lightBlue: '#DCEFF9', //sky
    sky25: '#F6FBFD',
    sky50: '#EDF7FC',
    lilRed: '#E63E1F',
  },
  agGrid: {
    disabledBackground: '#FAFAFA', //feather25
    overrideBackground: '#FFF3E0', //orange50
    overrideBackgroundDisabled: '#F8BC000F', //warning
    overrideBorder: '#F8BC00',
    overrideBorderHover: '#F8BC00B3',
  },
});

export default palette;
