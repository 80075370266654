export type FirmSearchRequestType = {
  searchText: string;
  isActive?: boolean;
};

export type FirmSearchResponseType = {
  result: Array<FirmSearchResultType>;
};

export type FirmSearchResultType = {
  firmId: number;
  firmName: string;
  isActive: boolean;
};

export type FirmListType = {
  name: string;
  value: number;
  display: string;
  isActive: boolean;
  accountNumber?: string;
};

export type LockboxNumberType = {
  name: string;
  value: number;
};

export type OrderRequestType = {
  firmId: number;
  lockboxId?: number;
  orderDate: string;
  referenceNumber?: string;
  checkCount?: number;
  reportedAmount?: number;
};

export type OrderType = OrderRequestType & {
  orderId: number;
  firm?: FirmSearchResultType;
  lockboxNumber?: LockboxNumberType;
};

export type FileType = {
  status?: string;
  fileId: string; // encrypted file id
  fileNumber: string;
  underwriter: string;
  accountNumber: number;
  propertyAddress: string;
  county: string;
  propertyType: string;
  policyNumbers: string[];
  policyNumbersSearch: string;
  policyDate: string; // YYYY-MM-DD
  gross: number;
  net: number;
  isRemitValid: boolean; // true: report files, false: review files
};

export type FileInProcessType = {
  status: string;
  reasonCode: string;
  fileId: number;
  fileNumber: string;
  batchId: number;
  firmId: number;
  firmName: string;
  remitBatchSourceId: number;
  remitBatchSource: string;
  referenceText: string;
  propertyState: string;
  assignedTo?: string;
  firstName?: string;
  lastName?: string;
  orderDate: string;
  checkCount: number;
  reportedAmount: number;
  assignedToGroup?: string;
};

export type FilterDateType = {
  type: string;
  dateFrom: string; // YYYY-MM-DD
  dateTo: string; // YYYY-MM-DD
};

export type FileCountsType = {
  allFiles: number;
  orderQueue: number;
};

export type SelectedFilesAmountType = {
  calculatedGross: number;
  calculatedNet: number;
  netDifference: number;
};

export type AccountInfoRequestType = {
  accountNumbers: number[];
};

export type AccountInfoType = {
  accountNumber: number;
  jurisdictionStateCode: string;
  underwriterCode: string;
  contractualSplit: number;
  costCenter: string;
  isNationalAgentFlag: boolean;
  remitAccountNumber: string;
  accountStatus: string;
};
export type BookOrderRequestType = {
  orderId: number;
  fileIds: Array<string>;
  email: string;
};

export type BookedOrderType = {
  orderId: number;
  orders: Array<FileOrderType>;
};

export type FileOrderType = {
  fileId: string;
  fileOrderId: number;
  orderNumber: string;
};

export type OrderFilesRequestType = {
  orderId: number;
  fileIds: string[];
  fileStatusId: number;
};

export type OrderFileQueueResponseType = {
  result: {
    rowCount: number;
    message: string;
  };
};

export enum ActivityRights {
  Admin = 138,
  View = 139,
  BookOrder = 140,
}
