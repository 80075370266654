import { useAuth } from '@agentnet/auth';
import { getCPLList } from 'api/cpl-api';
import { getFileInfoProps } from 'api/file/file-api';
import { fileStatuses } from 'features/constants';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import NoResults from 'ui-kit/components/noResults/NoResults';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { useViewState } from '../../hooks/ViewStateContext';
import CPLEdit from './cpl-edit/CPLEdit';
import CplList from './cpl-list/CplList';
import './CPLContainer.scss';
import { cplList } from './types';

const CPLContainer = (): JSX.Element => {
  const { fileId } = useParams<getFileInfoProps>();
  const history = useHistory();
  const { addGlobalMsg } = useGlobalMessages();
  const { getAccessToken } = useAuth();
  const loadCPLList = async (): Promise<cplList> => {
    const token = await getAccessToken();
    return getCPLList(fileId, token);
  };
  const [isCplClicked, setIsCplClicked] = useState<boolean>(false);
  const { execute, value, status: listStatus, errors: listStatusError } = useAsync<cplList>(loadCPLList, false);

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { profile, userFirm } = profileCtx;
  const {
    activeTab,
    setActiveTab,
    setTabValue,
    fileData,
    setFileData,
    setOpenPendingCpl,
    resetCpl,
    setResetCpl,
    setRemmittableCpl,
  } = fileDataCtx;
  const [showCPLList, setShowCPLList] = useState<boolean>(true);
  const [showNoCplsPage, setShowNoCplsPage] = useState<boolean>(true);
  const [editCPLId, setEditCPLId] = useState<string>('');
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const cplId = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const isStarsStatus = profile?.activityRights?.some((right) => right.ActivityRightId === 64);
  const associatedOffice = userFirm?.offices?.find((office) => office.officeId === fileData?.officeId);
  const associatedAccount = associatedOffice?.accounts?.find((account) => account.accountId === fileData?.accountId);
  const isAccountActive = associatedAccount?.status?.toLowerCase() === 'active';
  const isFileActive =
    userFirm?.status?.toLowerCase() === 'active' &&
    associatedOffice?.status?.toLowerCase() === 'active' &&
    associatedAccount?.status?.toLowerCase() === 'active';
  const isStarsStatusActive = isStarsStatus && !isFileActive;
  const noStarsStatusActive = !isStarsStatus && !isFileActive;
  const cplArr = value?.cpls || [];
  const cplsCount = cplArr.filter((item) => item.status === 'Pending' || item.status === 'Open').length;
  const { routerParamValue, setRouterParamValue } = useViewState();

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue]);

  useEffect(() => {
    setDisplayError(listStatus === 'error');
    if (listStatus === 'success') {
      setFileData &&
        setFileData((prevFileData: any) => ({
          ...prevFileData,
          serviceStatus: {
            ...prevFileData.serviceStatus,
            hasRemittableCpl: value?.hasRemittableCpl,
          },
        }));
    }
  }, [listStatus]);

  useEffect(() => {
    const errorMessages = listStatusError?.length ? listStatusError : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (activeTab === 'CPL') execute().then();
    if (activeTab === 'CPLEdit') setShowCPLList(false);
  }, [activeTab]);

  useEffect(() => {
    if (!value) execute().then();
  }, [fileId]);

  useEffect(() => {
    if (resetCpl) {
      execute().then();
      setResetCpl && setResetCpl(false);
    }
  }, [resetCpl]);

  const updateOpenPendingCount = (cpls: any) => {
    if (Array.isArray(cpls)) {
      const openPendingCpl = cpls.filter((item) => item.status === 'Pending' || item.status === 'Open');
      const isRemittable = openPendingCpl.filter((item) => item.isRemittableCpl).length > 0;
      setRemmittableCpl && setRemmittableCpl(isRemittable);
      setOpenPendingCpl && setOpenPendingCpl(openPendingCpl.length);
    }
  };

  useEffect(() => {
    if (value) {
      const { cpls } = value;
      if (activeTab === 'CPLEdit' && Array.isArray(cpls) && cpls.length === 0) {
        setShowCPLList(false);
      } else if (activeTab === 'CPL' && !value?.cpls?.length) {
        if (!isStarsStatusActive && !noStarsStatusActive) {
          openEditCPL();
        } else {
          hideEditCPL();
        }
      }
      if (Array.isArray(cpls) && cpls.length === 0) {
        setShowCPLList(false);
        setShowNoCplsPage(true);
      } else {
        setShowCPLList(true);
        setShowNoCplsPage(false);
      }
      updateOpenPendingCount(cpls);
    }
  }, [value]);

  useEffect(() => {
    if (editCPLId !== '' && cplId === 'cpl') history.push(`${window.location.pathname}/${editCPLId}`);
  }, [editCPLId]);

  useEffect(() => {
    value?.cpls.forEach((item) => {
      item.created = new Date(item?.created).toLocaleDateString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
      item.created = item.created.replace(',', '');
    });

    if (value) {
      if (cplId !== 'cpl') {
        const isCplValid = value?.cpls.some((cpl) => cpl.cplId === cplId);
        if (isCplValid) {
          setEditCPLId(cplId);
          setShowCPLList(false);
        }
      }
    }
  }, [value]);

  const openEditCPL = () => {
    if (setActiveTab) {
      setShowNoCplsPage(false);
      setShowCPLList(false);
      setActiveTab('CPLEdit');
    }
  };

  const hideEditCPL = () => {
    if (setActiveTab && activeTab !== 'CPL') {
      window.history.pushState({}, '', location.pathname.slice(0, location.pathname.indexOf('cpl') + 3));
      setActiveTab('CPL');
      setEditCPLId('');
      setShowCPLList(true);
    } else if (activeTab === 'CPL') {
      setResetCpl && setResetCpl(true);
    }
  };

  useEffect(() => {
    if (
      (fileData?.fileStatus === fileStatuses.openedInError ||
        fileData?.fileStatus === fileStatuses.cancelled ||
        fileData?.fileStatus === fileStatuses.closed) &&
      value?.cpls?.length === 0
    ) {
      setTabValue?.('0');
      history.push(`/files/${fileData?.fileId}/file-info`);
    }
  }, [fileData?.fileStatus, value?.cpls]);

  if (listStatus == 'pending') {
    return (
      <div className={listStatus === 'pending' ? 'loading-container' : ''}>
        <LoadingSpinner status={listStatus} size={30} />
      </div>
    );
  }

  return (
    <div className="w-100">
      {value?.cpls && isAccountActive ? (
        <div className="paper-container">
          {showCPLList ? (
            <CplList
              onCancel={hideEditCPL}
              cplListData={value}
              isCplClicked={isCplClicked}
              handleAddNewCPL={openEditCPL}
              updateOpenPendingCount={updateOpenPendingCount}
              isStarsStatusActive={isStarsStatusActive}
              noStarsStatusActive={noStarsStatusActive}
              profile={profile}
            />
          ) : (
            <CPLEdit
              cplId={editCPLId}
              cplListData={value}
              cplsCount={cplsCount}
              onCancel={hideEditCPL}
              isStarsStatusActive={isStarsStatusActive}
              noStarsStatusActive={noStarsStatusActive}
              isEditMode
              isNewCpl
              profile={profile}
            />
          )}
        </div>
      ) : (
        <>
          <NoResults
            showButton={false}
            type="results"
            titleText="No CPLs Associated to This File"
            pText="Due to inactive status, this file can not create CPLs."
          />
        </>
      )}
    </div>
  );
};

export default CPLContainer;
