import { useAuth } from '@agentnet/auth';
import { Theme } from '@agentnet/components';
import { CellLink, CellStatus, Content, DataTable, PageHeader, pxToRem } from '@fluentsms/agentnet-web-components';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { getFilesInProcessApi } from 'api/orderManagement/order-management-api';
import clsx from 'clsx';
import { numberValueFormatter } from 'features/orderManagement/fileListConfig';
import { FileInProcessType } from 'features/orderManagement/types';
import CalculatorRenderer from 'features/Remittance/CalculatorRenderer';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import ContentContainer from 'ui-kit/components/utility/ContentContainer';
import ReportsAndPayDrawer from '../ReportsAndPayDrawer';

export function FilesInProcessContent() {
  const { getAccessToken } = useAuth();
  const gridApiRef = useRef<GridApi<FileInProcessType> | null>(null);
  const { addGlobalMsg } = useGlobalMessages();
  const [filesInProcess, setFilesInProcess] = useState<Array<FileInProcessType>>([]);
  const [selectedFiles, setSelectedFiles] = useState<Array<FileInProcessType>>([]);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: pxToRem(114),
      },
      selectedRows: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100% !important',
        fontSize: pxToRem(14),
        padding: theme.spacing(3),
        paddingBottom: 12,
      },
      heading: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 20,
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
      tableDefault: {
        height: `calc(100vh - ${filesInProcess?.length ? '267px' : '207px'}) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      paddingX: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        width: 'auto',
      },
      noRows: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 220,
        justifyContent: 'center',
      },
    }),
  );
  const classes = useStyles();

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-testid={'OrderManagement-FilesInProcess-AllCheckbox'} />;
  };

  const rowCheckboxRenderer = (params: { node: RowNode }) => {
    return <input type="checkbox" data-testid={`OrderManagement-FilesInProcess-Checkbox-${params.node.id}`} />;
  };

  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    profileCtx.userFirm?.firmId ?? '',
    'order-management',
  );

  const onGridReady = (params: GridReadyEvent<FileInProcessType>) => {
    gridApiRef.current = params.api;
  };

  const onFilterChanged = (): void => {
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const getDisplayedRowCount = () => {
    if (gridApiRef.current) return gridApiRef.current.getDisplayedRowCount();
    return 0;
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridApiRef.current?.getSelectedRows() ?? [];
    setSelectedFiles(selectedRows);
  }, [selectedFiles]);

  const netAmountTotal = selectedFiles?.reduce(
    (accumulator: number, obj: FileInProcessType) => accumulator + obj.reportedAmount,
    0,
  );

  const selectedFilesText = `Selecting ${selectedFiles?.length} file(s) for ${numberValueFormatter(
    netAmountTotal,
    true,
  )}`;

  const defaultColDef: ColDef = {
    flex: 2,
    floatingFilter: true,
    filter: true,
    sortable: true,
    resizable: true,
    editable: false,
    suppressMenu: true,
    filterParams: { closeOnApply: true, suppressAndOrCondition: true },
  };

  const getColumnConfig = (): ColDef[] => {
    return [
      {
        field: '',
        maxWidth: 40,
        filter: false,
        floatingFilter: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        pinned: 'left',
        headerComponent: headerCheckboxRenderer,
        cellRenderer: rowCheckboxRenderer,
        resizable: false,
      },
      {
        colId: 'statusColumn',
        headerName: 'Status',
        field: 'status',
        filter: 'agSetColumnFilter',
        minWidth: 144,
        maxWidth: 256,
        tooltipField: 'status',
        cellRenderer: (params: ICellRendererParams) => {
          return <CellStatus status={params.data?.status || ''} />;
        },
      },
      {
        colId: 'reasonCodeColumn',
        headerName: 'Reason Code',
        field: 'reasonCode',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agSetColumnFilter',
        tooltipField: 'reasonCode',
      },
      {
        colId: 'fileIdColumn',
        headerName: 'File Id',
        field: 'fileId',
        filter: 'agNumberColumnFilter',
        tooltipField: 'fileId',
        hide: true,
      },
      {
        colId: 'fileNumberColumn',
        headerName: 'File Number',
        field: 'fileNumber',
        minWidth: 144,
        maxWidth: 384,
        filter: 'agTextColumnFilter',
        tooltipField: 'fileNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        cellRenderer: CellLink,
        // temporary open in new tab until we have a proper order management navigation menu
        cellRendererParams: (row: ValueFormatterParams) => ({
          linkTemplate: `/files/${row.data.fileId}/file-info`,
          openNewTab: true,
        }),
      },
      {
        colId: 'batchIdColumn',
        headerName: 'Order Id',
        field: 'batchId',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agNumberColumnFilter',
        tooltipField: 'batchId',
      },
      {
        colId: 'firmId',
        headerName: 'Firm Id',
        field: 'firmId',
        filter: 'agNumberColumnFilter',
        tooltipField: 'firmId',
        hide: true,
      },
      {
        colId: 'firmNameColumn',
        headerName: 'Firm',
        field: 'firmName',
        minWidth: 256,
        filter: 'agTextColumnFilter',
        tooltipField: 'firm',
      },
      {
        colId: 'lockboxTicketNumberColumn',
        headerName: 'Lockbox/Ticket Number',
        field: 'remitBatchSource',
        minWidth: 256,
        filter: 'agTextColumnFilter',
        tooltipField: 'lockboxTicketNumber',
      },
      {
        colId: 'propertyStateColumn',
        headerName: 'Property State',
        field: 'propertyState',
        minWidth: 144,
        maxWidth: 256,
        filter: 'agTextColumnFilter',
        tooltipField: 'propertyState',
      },
      {
        colId: 'assigneeColumn',
        headerName: 'Assignee',
        field: 'assignedTo',
        minWidth: 256,
        filter: 'agTextColumnFilter',
        tooltipField: 'assignedTo',
      },
      {
        field: '',
        floatingFilterComponentParams: { suppressFilterButton: true },
        suppressHeaderFilterButton: true,
        floatingFilter: false,
        sortable: false,
        cellRenderer: CalculatorRenderer,
        cellRendererParams: () => ({
          tabName: 'order-management',
          //isRemitValid: row.data.isRemitValid,
          isRemitValid: true,
        }),
        onCellClicked: (event: CellClickedEvent) => {
          handleDrawerOpen(event, 'order-management');
        },
        maxWidth: 40,
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        tooltipValueGetter: () => 'Open Rates and Fees',
        pinned: 'right',
      },
    ];
  };

  const CustomOverlayLoading = () => {
    return (
      <div className={classes.noRows}>
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const getFilesInProcess = async (): Promise<Array<FileInProcessType>> => {
    const token = await getAccessToken();
    const response = await getFilesInProcessApi(token);
    return response.result;
  };

  const {
    execute: executeGetFilesInProcess,
    status: executeGetFilesInProcessStatus,
    value: getFilesInProcessValue,
    errors: getFilesInProcessErrors,
  } = useAsync<Array<FileInProcessType>>(getFilesInProcess, false);

  useEffect(() => {
    executeGetFilesInProcess();
  }, []);

  useEffect(() => {
    if (executeGetFilesInProcessStatus === 'success') {
      setFilesInProcess(getFilesInProcessValue ?? []);
    }
    if (executeGetFilesInProcessStatus === 'error') {
      getFilesInProcessErrors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [executeGetFilesInProcessStatus]);

  useEffect(() => {
    setDisplayedRowCount(getDisplayedRowCount());
  }, [displayedRowCount]);

  if (!getFilesInProcessValue) {
    return (
      <ContentContainer fullWidth>
        <Content>
          <CustomOverlayLoading />
        </Content>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer fullWidth>
      <Content>
        <div>
          <PageHeader className={classes.heading} title="Files In Process" />
          <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
            <DataTable
              rowData={filesInProcess}
              columnDefs={getColumnConfig()}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFilterChanged={onFilterChanged}
              onSelectionChanged={onSelectionChanged}
              gridOptions={{ suppressRowClickSelection: true, rowSelection: 'multiple' }}
              animateRows={true}
              noRowsOverlayComponent={'customOverlayNoRows'}
            />
          </div>
          <div className={clsx(classes.paddingX, classes.selectedRows)}>
            <span>
              Showing {displayedRowCount || getFilesInProcessValue.length} of {getFilesInProcessValue.length} {'files.'}
            </span>
            {selectedFiles?.length ? selectedFilesText : null}
          </div>
          <ReportsAndPayDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            drawerData={drawerData}
            setDrawerData={setDrawerData}
            rateAndFeeExecute={rateAndFeeExecute}
          />
        </div>
      </Content>
    </ContentContainer>
  );
}
